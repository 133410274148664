import React from 'react';
import {Button} from 'antd';
import qs from 'query-string';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';

const AppProxy = (props) => {
  const {location, pageContext} = props;
  const {issuer} = pageContext;
  const {search} = location;
  const params = qs.parse(search);

  return (
    <Wrapper>
      <Button
        onClick={() => {
          if (issuer === 'reset-email') {
            window.open(
              `rocks.pokemonstore.stage://ResetEmail?access_token=${params.access_token}&step=3`,
            );
          } else if (issuer === 'register') {
            window.open(
              `rocks.pokemonstore.stage://Register?access_token=${params.access_token}&step=3`,
            );
          } else if (issuer === 'forgot-password') {
            window.open(
              `rocks.pokemonstore.stage://ForgotPassword?access_token=${params.access_token}&step=3`,
            );
          }
        }}>
        前往 App 完成程序
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 64px - 240px);
  padding-top: var(--topNavBarHeight);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withPageEntry(AppProxy);
